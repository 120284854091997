import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React from 'react';
import { useDocumentDialogState } from './use-dialog-state';
export default function ViewSelection() {
    const [{ view }, stateProxy] = useDocumentDialogState();
    const onSelectView = (view) => () => {
        stateProxy.view = view;
    };
    const formSelectClass = useComposedClassName(function* () {
        yield 'select-form';
        if (view === 'Forms') {
            yield 'active';
        }
    }, [view]);
    const pageSelectClass = useComposedClassName(function* () {
        yield 'select-page';
        if (view === 'Pages') {
            yield 'active';
        }
    }, [view]);
    return (React.createElement("div", { className: "view-selection" },
        React.createElement("button", { className: formSelectClass, onClick: onSelectView('Forms') },
            React.createElement("i", { className: "fal fa-fw fa-input-text" }),
            "Forms"),
        React.createElement("button", { className: pageSelectClass, onClick: onSelectView('Pages') },
            React.createElement("i", { className: "fal fa-fw fa-file-invoice" }),
            "Pages")));
}
