import { useTabContent } from '@rapid/convergence';
import { store, useListItem } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import useRpformUrl from '../hooks/use-rpform-url';
import copyToClipboard from '../utils/copy-to-clipboard';
const DOCS_EP = 'adaptive-documents';
const DOCS_LIST = 'Adaptive Documents';
const LINKS_KEY = 'stateLinks';
/**
 * Returns the Document from the store at the provided id
 *
 * @params {number} id - The id of the document to get from the store
 * @returns {[Readonly<ListItemMixin<AnyListItem>>, ListItemMixin<AnyListItem>]} links - The links returned from the get request
 */
export default function useDocument(id) {
    const item = useListItem(DOCS_LIST, id);
    return item;
}
/**
 * Returns the current Document matched in useTabContent from the store at the provided id
 *
 * @params {number} id - The id of the document to get from the store
 * @returns {[Readonly<ListItemMixin<AnyListItem>>, ListItemMixin<AnyListItem>]} links - The links returned from the get request
 */
export function useCurrentDocument() {
    const { match } = useTabContent();
    return useDocument(+match.params.id);
}
export function addIdToWorkspace(id) {
    if (!Array.isArray(store.applicationData.workspace)) {
        store.applicationData.workspace = [];
    }
    if (!store.applicationData.workspace?.some((i) => i === id)) {
        store.applicationData.workspace.push(id);
    }
}
export function removeIdFromWorkspace(id) {
    if (!Array.isArray(store.applicationData.workspace)) {
        return;
    }
    const ind = store.applicationData.workspace.findIndex(i => i === id);
    store.applicationData.workspace.splice(ind, 1);
}
export function setActiveDocument(id) {
    store.applicationData.activeDocument = id;
    addIdToWorkspace(id);
}
/**
 * Pulls the link matching the provided state key from the document links collection
 *
 * @params {string} [stateKey] - A state key to add to the link, if left blank no state key is added
 * @returns {IPublicFormView[]} links - The links returned from the get request
 */
export function useStateLink(stateKey) {
    const { match } = useTabContent();
    const [doc] = useDocument(+match.params.id);
    const link = doc.$data?.stateLinks?.find((link) => link.state_key === stateKey);
    const state = doc?.document?.head?.states?.[stateKey];
    const url = useRpformUrl(link?.id);
    const openLink = useCallback(() => {
        window.open(url, '_blank');
    }, [url]);
    const copyLink = useCallback(() => {
        copyToClipboard(url);
    }, [url]);
    const deleteLink = useCallback(() => {
        return toast.promise(deleteDocumentLink(+doc.id, link), {
            pending: `Deleting link for ${state}`,
            success: 'Link deleted successfully',
            error: 'Error deleting link',
        });
    }, [doc, link, state]);
    const generateLink = useCallback(() => {
        return toast.promise(addDocumentLink(+doc.id, stateKey), {
            pending: `Creating link for ${state}`,
            success: 'Link created successfully',
            error: 'Error creating link',
        });
    }, [doc, stateKey, state]);
    return {
        url,
        copyLink,
        openLink,
        generateLink,
        deleteLink,
        link,
    };
}
/**
 * Fetches all links for the provided adaptive document id
 *
 * Internally adds the links to the correct document store for use elsewhere
 *
 * @params {ISiteAPIEndpoint} ep - The current site endpoint
 * @params {number} id - Adaptive document id
 * @returns {IPublicFormView[]} links - The links returned from the get request
 */
export function getDocumentLinks(id) {
    const document = getListItem(DOCS_LIST, id);
    const links = document.getDataArray(LINKS_KEY, store.api[DOCS_EP][id].links);
    return links;
}
/**
 * Deletes the provided link and removes it from the document links collections
 *
 * @params {number} id - Adaptive document id
 * @params {IPublicFormView} link - The link to delete
 */
export async function deleteDocumentLink(id, link) {
    try {
        await store.api[DOCS_EP][id].permalink.delete(undefined, {
            links: [link.id],
        });
    }
    catch (e) {
        toast.error(`There was an error deleting the link: ${e}`);
    }
    getDocumentLinks(id);
}
/**
 * Generates a new link and refetches the links collection in the document store
 *
 * @params {ISiteAPIEndpoint} ep - The current site endpoint
 * @params {number} id - Adaptive document id
 * @params {string} [state_key] - A state key to add to the link, if left blank no state key is added
 * @returns {IPublicFormView[]} links - The links returned from the get request
 */
export async function addDocumentLink(id, state_key) {
    try {
        await store.api[DOCS_EP][id].permalink.postJson({}, {
            persistant: true,
            state_key,
        });
    }
    catch (e) {
        toast.error(`There was an error generating the link: ${e}`);
    }
    const links = getDocumentLinks(id);
    return links.find(link => link.state_key === state_key);
}
/**
 * Returns a collection of document states
 *
 * @returns {IDesignerFormState[]} states - The states of the document
 */
export function useDocumentStates(id) {
    const [document] = useDocument(id);
    const states = useMemo(function buildDesignerFormStates() {
        const documentStates = Object.entries(document?.document?.head?.states ?? {});
        const states = documentStates.reduce((prev, [stateKey, state]) => {
            prev.push({
                ...(state ?? {}),
                key: stateKey,
            });
            return prev;
        }, []);
        return states;
    }, [document.document?.head?.states]);
    return states;
}
/**
 * Returns the specified document state if it exists
 *
 * @returns {IAdaptiveFormState} state - The state of the document
 */
export function useDocumentState(id, stateKey) {
    const [doc] = useDocument(id);
    const state = doc?.document?.head?.states?.[stateKey];
    return state;
}
/**
 * Returns the current document returned from useTabContent
 *
 * @returns {IDesignerFormState[]} states - The states of the document
 */
export function useCurrentDocumentStates() {
    const { match } = useTabContent();
    return useDocumentStates(+match.params.id);
}
/**
 * Returns the current document returned from useTabContent
 *
 * @returns {IAdaptiveFormState} state - The state of the document
 */
export function useCurrentDocumentState(stateKey) {
    const { match } = useTabContent();
    return useDocumentState(+match.params.id, stateKey);
}
export async function getSiteTheme() {
    const [, _tenant] = location.pathname.replace(/^\//, '').split('/');
    if (!_tenant)
        return;
    if (document.querySelector('style#rapid-theme-style'))
        return;
    const style = document.createElement('style');
    style.id = 'rapid-theme-style';
    const result = await fetch(`https://cdn.rapidplatform.com/themes/${_tenant.toLocaleLowerCase()}/theme.css`).then(res => res.text());
    style.innerHTML = result;
    document.head.appendChild(style);
}
