import { useTabContext } from '@rapid/convergence';
import React from 'react';
import useDocument from '../../store/designer-store';
import openDialog from '../dialog/open-dialog';
export default function ActiveDocument({ documentId, listVisible, toggleList, }) {
    const { addTab } = useTabContext();
    const id = isNaN(+documentId) ? undefined : +documentId;
    const [document] = useDocument(id);
    const onToggleList = () => {
        toggleList();
    };
    const onOpenPreview = () => {
        addTab(`documents/${id}/preview`, true);
    };
    const onOpenOverview = () => {
        addTab(`documents/${id}/overview`, true);
    };
    const onDialogSave = (id) => {
        addTab(`${id}/design`, true);
    };
    const onDialogCancel = () => {
        console.log('Cancelled');
    };
    const onOpenDocument = () => {
        openDialog('open', onDialogSave, onDialogCancel);
    };
    return (React.createElement("div", { className: "active-document" },
        !id && (React.createElement("div", { className: "no-active-document", onClick: onOpenDocument }, "Open a document")),
        !!id && !!document && (React.createElement("div", { className: "document", title: document?.title }, document?.title ?? React.createElement("span", { className: "loading" }, "loading..."))),
        React.createElement("div", { className: "actions" }, !!id && !!document && (React.createElement(React.Fragment, null,
            React.createElement("button", { className: "open-overview", title: "Open document overview", onClick: onOpenOverview },
                React.createElement("i", { className: "fal fa-fw fa-info-circle" })),
            React.createElement("button", { className: "open-preview", title: "Open document preview", onClick: onOpenPreview },
                React.createElement("i", { className: "fal fa-fw fa-eye" })),
            React.createElement("button", { className: "toggle-documents-list", title: listVisible ? 'Hide documents list' : 'Show documents list', onClick: onToggleList }, listVisible ? (React.createElement("i", { className: "fal fa-fw fa-chevron-down" })) : (React.createElement("i", { className: "fal fa-fw fa-chevron-up" }))))))));
}
