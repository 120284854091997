import { proxy } from 'valtio';
const formDetailsDocument = proxy({
    $type: 'Layout.Section',
    id: 'root',
    $children: [
        {
            $type: 'Input.Text',
            id: 'document-title',
            dataPath: '$.document.head.title',
            attributes: {
                label: 'Title',
            },
        },
        {
            $type: 'Input.Text',
            id: 'document-slug',
            dataPath: '$.slug',
            attributes: {
                label: 'Permalink',
                disabled: true,
                dependantData: ['document-title'],
            },
        },
        {
            $type: 'Input.Choice',
            id: 'document-classification',
            dataPath: '$.classification',
            attributes: {
                label: 'Classification',
                choices: [
                    { Text: 'Draft', AccentColour: 'var(--warning-solid-background)' },
                    {
                        Text: 'Public',
                        AccentColour: 'var(--primary-solid-background)',
                    },
                    {
                        Text: 'Archived',
                        AccentColour: 'var(--danger-solid-background)',
                    },
                ],
            },
        },
        {
            $type: 'Input.MultiLineText',
            id: 'document-description',
            dataPath: '$.description',
            attributes: {
                label: 'Description',
            },
        },
        {
            $type: 'Layout.Section',
            id: 'related-forms',
            className: "children-margin-left-subtle",
            attributes: {
                heading: true,
                headingType: 'h4',
                label: 'Related Forms',
                showDescription: true,
                description: "Configuration for forms to be displayed on meta form events."
            },
            $children: [
                {
                    $type: 'Layout.Section',
                    id: 'related-forms-postSubmit',
                    attributes: {
                        heading: true,
                        headingType: 'h5',
                        label: 'Post Submit',
                        showDescription: true,
                        description: "Form to display after public form submission."
                    },
                    $children: [
                        {
                            $type: 'Input.Lookup',
                            id: 'related-forms-postSubmit-success',
                            dataPath: '$.document.head.relatedForms.postSubmit.success',
                            attributes: {
                                label: 'On Success',
                                lookupList: 'Adaptive Documents',
                                lookupField: 'Title',
                            },
                        },
                        {
                            $type: 'Input.Lookup',
                            id: 'related-forms-postSubmit-error',
                            dataPath: '$.document.head.relatedForms.postSubmit.error',
                            attributes: {
                                label: 'On Error',
                                lookupList: 'Adaptive Documents',
                                lookupField: 'Title',
                            },
                        },
                    ],
                },
            ],
        },
    ],
});
const pageDetailsDocument = proxy({
    $type: 'Layout.Section',
    id: 'root',
    $children: [
        {
            $type: 'Input.Text',
            id: 'document-title',
            dataPath: '$.document.head.title',
            attributes: {
                label: 'Title',
            },
        },
        {
            $type: 'Input.Choice',
            id: 'document-classification',
            dataPath: '$.classification',
            attributes: {
                label: 'Classification',
                choices: [
                    { Text: 'Draft', AccentColour: 'var(--warning-solid-background)' },
                    {
                        Text: 'Public',
                        AccentColour: 'var(--primary-solid-background)',
                    },
                    {
                        Text: 'Archived',
                        AccentColour: 'var(--danger-solid-background)',
                    },
                ],
            },
        },
        {
            $type: 'Input.MultiLineText',
            id: 'document-description',
            dataPath: '$.description',
            attributes: {
                label: 'Description',
            },
        },
    ],
});
export { formDetailsDocument, pageDetailsDocument };
