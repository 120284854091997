import { useQueryStringProxy } from '@rapid/convergence';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EditableTitle from '../editable-title/editable-title';
export default function StateOverviewLabel({ state, stateKey, link, onGenerateLink, }) {
    const qs = useQueryStringProxy();
    const [editingName, setEditingName] = useState(false);
    const [, setQuery] = useSearchParams();
    // FIXME: Replace
    // const [, , contextProxy] = useListItemContext();
    const contextProxy = {};
    // const states = useDocumentStates();
    const states = [];
    const [newName, setNewName] = useState('');
    const onDeleteState = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!!contextProxy.item) {
            delete contextProxy.item.document.head.states[stateKey];
        }
        delete qs.state;
        setQuery(qs.toString());
    };
    const onChangeStateName = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditingName(true);
    };
    const onNameChange = (e) => {
        setNewName(e.target.value);
    };
    const confirmNameChange = () => {
        const isDuplicate = states.some(i => i.label === newName);
        if (isDuplicate) {
            toast.warn(`All state keys must be unique. ${newName} has already been used.`);
            return;
        }
        const isEmpty = !newName;
        if (isEmpty) {
            return;
        }
        if (qs.state === stateKey) {
            qs.state = newName;
            setQuery(qs.toString());
        }
        if (!!contextProxy.item) {
            contextProxy.item.document.head.states[stateKey].label = newName;
        }
        setEditingName(false);
    };
    const onConfirmNameChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        confirmNameChange();
    };
    const cancelNameChange = () => {
        setNewName('');
        setEditingName(false);
    };
    const onCancelNameChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        cancelNameChange();
    };
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            confirmNameChange();
        }
        if (e.key === 'Escape') {
            cancelNameChange();
        }
    };
    return (React.createElement(EditableTitle, { title: state?.label, editingTitle: newName, onTitleChange: onNameChange, onKeyDown: onKeyDown, placeholder: state?.label, editing: editingName, tags: React.createElement("div", { className: "link-details" },
            !link && (React.createElement("button", { className: "add-link", title: "Generate link", onClick: onGenerateLink },
                React.createElement("i", { className: "add-link-icon fak fa-fw fa-link-plus" }))),
            !!link && (React.createElement("button", { className: "has-link", title: "This state has an active share link" },
                React.createElement("i", { className: "has-link-icon fal fa-fw fa-link" })))), actions: React.createElement(React.Fragment, null,
            React.createElement("button", { className: "change-state-name", title: "Change state name", onClick: onChangeStateName },
                React.createElement("i", { className: "change-state-name-icon fal fa-fw fa-pencil" })),
            React.createElement("button", { className: "delete-state", title: "Delete state and link", onClick: onDeleteState },
                React.createElement("i", { className: "delete-state-icon fal fa-fw fa-trash-can" }))), editingActions: React.createElement(React.Fragment, null,
            React.createElement("button", { className: "confirm-name-change", title: "Confirm name change", onClick: onConfirmNameChange },
                React.createElement("i", { className: "confirm-name-change-icon fal fa-fw fa-check" })),
            React.createElement("button", { className: "cancel-name-change", title: "Cancel name change", onClick: onCancelNameChange },
                React.createElement("i", { className: "cancel-name-change-icon fal fa-fw fa-times" }))) }));
}
