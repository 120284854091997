import { AdaptiveRenderer } from '@rapid/adaptive-framework';
import React from 'react';
import { proxy } from 'valtio';
const documentDetailsDocument = proxy({
    $type: 'Layout.Section',
    id: 'root',
    $children: [
        {
            $type: 'Input.Text',
            id: 'document-title',
            dataPath: '$.document.head.title',
            attributes: {
                label: 'Name',
            },
        },
        {
            $type: 'Input.Choice',
            id: 'document-classification',
            dataPath: '$.classification',
            attributes: {
                label: 'Classification',
                defaultValue: 'Draft',
                choices: [
                    { Text: 'Draft', AccentColour: 'var(--warning-solid-background)' },
                    {
                        Text: 'Public',
                        AccentColour: 'var(--primary-solid-background)',
                    },
                    {
                        Text: 'Archived',
                        AccentColour: 'var(--danger-solid-background)',
                    },
                ],
            },
        },
        {
            $type: 'Input.MultiLineText',
            id: 'document-description',
            dataPath: '$.description',
            attributes: {
                label: 'Description',
            },
        },
    ],
});
export default function PagesWizard({ item, onDataChange, rendererId, }) {
    return (React.createElement("div", { className: "wizard" },
        React.createElement(AdaptiveRenderer, { onDataChange: onDataChange, data: item, rendererId: rendererId, document: documentDetailsDocument, mode: "edit" })));
}
