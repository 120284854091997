import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Preview from './components/preview/preview';
import StateDashboard from './components/state/state-dashboard';
import Overview from './components/overview/overview';
import Designer from './components/designer/designer';
import DesignSurface from './design-surface';
import '@rapid/convergence/dist/convergence.css';
import { RapidApplication } from '@rapid/sdk/lib/ui';
import '@rapid/sdk/css/sdk.css';
export default function App() {
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(RapidApplication, { subRoutes: [
                {
                    id: 'designer-root',
                    path: '',
                    element: React.createElement(DesignSurface, null),
                    children: [
                        {
                            id: 'overview',
                            path: ':type/:id/overview',
                            element: React.createElement(Overview, null),
                        },
                        {
                            id: 'design',
                            path: ':type/:id/design',
                            element: React.createElement(Designer, null),
                        },
                        {
                            id: 'preview',
                            path: ':type/:id/preview',
                            element: React.createElement(Preview, null),
                        },
                        {
                            id: 'state',
                            path: ':type/:id/state/:state',
                            element: React.createElement(StateDashboard, null),
                        },
                    ],
                },
            ] })));
}
