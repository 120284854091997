import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { useApplicationStore } from '@rapid/sdk';
import React from 'react';
import CloneDialog from './clone-document-dialog';
import CreateDialog from './create-document-dialog';
import OpenDialog from './open-document-dialog';
import ViewSelection from './view-selection';
import DeleteDialog from './delete-document-dialog';
export default function Dialog({ onCancel, onSave, className, type, }) {
    const [appData] = useApplicationStore();
    const dialogClass = useComposedClassName(function* () {
        yield 'dialog';
        if (!!className) {
            yield className;
        }
    }, [className]);
    if (type === 'delete') {
        return (React.createElement("div", { className: dialogClass },
            React.createElement(DeleteDialog, { onCancel: onCancel, onSave: onSave })));
    }
    return (React.createElement(React.Fragment, null,
        appData.documentDialog.view !== 'Apps' && type !== 'clone' && (React.createElement(ViewSelection, null)),
        React.createElement("div", { className: dialogClass },
            type === 'open' && React.createElement(OpenDialog, { onCancel: onCancel, onSave: onSave }),
            type === 'create' && (React.createElement(CreateDialog, { onCancel: onCancel, onSave: onSave })),
            type === 'clone' && (React.createElement(CloneDialog, { onCancel: onCancel, onSave: onSave })))));
}
