/**
 * Checks if the tab exists and if not adds it
 *
 * @params {Record<string, string>} qs - The query string proxy
 * @params {string} newTab - The new tab to add
 * @returns {Record<string, string>} qs - The updated query string proxy
 */
export default function addTab(qs, newTab) {
    if (!qs.tabs) {
        qs.tabs = newTab;
        return qs;
    }
    const hasTab = qs.tabs.includes(newTab);
    if (hasTab) {
        return qs;
    }
    const tabs = qs.tabs.split(',');
    tabs.push(newTab);
    qs.tabs = tabs.join(',');
    return qs;
}
