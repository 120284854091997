import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCloseTab from '../../hooks/use-close-tab';
import useDocument, { addIdToWorkspace, setActiveDocument, } from '../../store/designer-store';
export default function TabComponent({ match }) {
    const navigate = useNavigate();
    const location = useLocation();
    const closeTab = useCloseTab(match);
    const [document] = useDocument(+match.params.id);
    function navigateToPath() {
        const path = match.pathname.replace('/', '') ?? match.pathname ?? location.pathname;
        setActiveDocument(+document.id);
        navigate({
            pathname: `${path}`,
            search: location.search,
        });
    }
    const [active, setActive] = useState(false);
    useEffect(function getActiveStateOnMount() {
        if (location.pathname.includes(match.pathname)) {
            setActive(true);
            setActiveDocument(+match.params.id);
        }
        else if (active) {
            setActive(false);
        }
        addIdToWorkspace(+match.params.id);
    }, [match, location.pathname]);
    const tabClass = useComposedClassName(function* () {
        yield 'designer-tab';
        if (!!active) {
            yield 'active';
        }
        if (document.$state.dirty) {
            yield 'dirty';
        }
    }, [active, document.$state.dirty]);
    const type = match.pathname.replace(/^\//, '').split('/')[2];
    const iconClass = useComposedClassName(function* () {
        yield 'tab-type-icon';
        if (type === 'design') {
            yield 'fak fa-fw ';
        }
        else {
            yield 'fal fa-fw ';
        }
        if (type === 'design') {
            yield 'fa-adaptive-document-light';
        }
        if (type === 'preview') {
            yield 'fa-eye';
        }
        if (type === 'state') {
            yield 'fa-rectangle-history';
        }
        if (type === 'overview') {
            yield 'fa-info-circle';
        }
    }, [type]);
    return (React.createElement("div", { role: "tab", className: tabClass, onClick: navigateToPath, title: document?.title },
        React.createElement("i", { className: iconClass }),
        React.createElement("div", { className: "tab-content" },
            React.createElement("div", { className: "tab-header" },
                React.createElement("span", { className: "tab-type" }, type),
                React.createElement("div", { className: "actions" },
                    React.createElement("button", { className: "close-tab", onClick: closeTab, title: !!document.$state.dirty ? 'close without saving' : 'close' },
                        React.createElement("i", { className: "far fa-fw fa-xmark" })))),
            React.createElement("div", { className: "tab-title" }, document?.title))));
}
