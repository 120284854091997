import { isAdaptiveElement, returnAdaptiveElement, returnAdaptiveParent, } from '@rapid/adaptive-framework';
import { useCallback } from 'react';
export function useOnTreeChange(docProxy, deps) {
    const onTreeChange = useCallback(function onTreeChangeCB(dragged, dropped, parent, position) {
        //
        //
        // Check that both elements are IElement's
        //
        if (isAdaptiveElement(parent.element) &&
            isAdaptiveElement(dragged.element)) {
            //
            //
            // Get the dragged element from the proxy document
            //
            const draggedProxy = returnAdaptiveElement(dragged.element.id, docProxy.document.body);
            //
            //
            // Get the parent of the dragged element from the proxy document
            //
            const draggedParent = returnAdaptiveParent(dragged.element.id, docProxy.document.body);
            //
            //
            // Check if the dragged parent is the same element as the dropped parent
            //
            const isSameParent = draggedParent.id === parent.element.id;
            let dropParent;
            //
            //
            // If it's not the same parent element
            // get the new parent element
            //
            if (!isSameParent) {
                dropParent = returnAdaptiveElement(parent.element.id, docProxy.document.body);
            }
            else {
                dropParent = draggedParent;
            }
            //
            // Finally
            // Remove dragged element from it's parent
            //
            const draggedInd = draggedParent.$children.findIndex(e => e.id === draggedProxy.id) ?? -1;
            if (draggedInd !== -1) {
                draggedParent.$children.splice(draggedInd, 1);
            }
            //
            //
            // If the dropParent has children
            //
            if (Array.isArray(dropParent.$children)) {
                //
                //
                // Find the index of the drop target in the parent element
                //
                const dropInd = dropParent.$children?.findIndex(e => e.id === dropped.element.id) ??
                    -1;
                //
                //
                // If the drop target is a child of the dropParent
                // splice the dragged in
                //
                if (dropInd !== -1 && typeof position === 'number') {
                    const dropPos = dropInd + position;
                    dropParent.$children.splice(dropPos, 0, draggedProxy);
                }
                //
                // If the drop target is not a child of the dropParent
                // push the element into the drop parent
                //
                if (dropInd === -1 && typeof position === 'number') {
                    dropParent.$children.push(draggedProxy);
                }
            }
            else {
                //
                //
                // Else if the dropParent has no children make them
                //
                dropParent.$children = [draggedProxy];
            }
        }
    }, deps);
    return onTreeChange;
}
