import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { useViewItems } from '@rapid/sdk';
import React, { useEffect, useState } from 'react';
import { setActiveDocument } from '../../store/designer-store';
import { FixedSizeList as List } from 'react-window';
import CreationWizard, { createDocument, } from '../creation-wizard/creation-wizard';
import { useDocumentDialogState } from './use-dialog-state';
const isV2Filter = {
    $filter: 'is_v3 eq false'
};
export default function OpenDialog({ onSave, onCancel }) {
    const [state, stateProxy] = useDocumentDialogState();
    const [term, setTerm] = useState('');
    const onFocus = (focus) => () => {
        stateProxy.searchHasFocus = focus;
    };
    const [view, viewProxy] = useViewItems('Adaptive Documents', state?.view ?? 'Forms');
    useEffect(() => {
        view.get(isV2Filter);
    }, [state?.view]);
    const onSelect = (item) => () => {
        if (state?.view === 'Apps') {
            onSave(item);
            return;
        }
        setActiveDocument(+item.id);
        onSave(`documents/${item.id}`);
    };
    const onChangeTerm = (e) => {
        setTerm(e.target.value);
        viewProxy.search(e.target.value, isV2Filter);
    };
    const onClearSearch = () => {
        setTerm('');
        viewProxy.search('', isV2Filter);
    };
    const onKeyDown = (e) => {
        if (e.key === 'Escape' && !!view.$state.term) {
            e.stopPropagation();
            e.preventDefault();
            onClearSearch();
        }
    };
    const searchClass = useComposedClassName(function* () {
        yield 'search';
        if (state?.searchHasFocus) {
            yield 'focus';
        }
    }, [state?.searchHasFocus]);
    const iconClass = useComposedClassName(function* () {
        yield 'fal fa-fw';
        if (state?.view === 'Forms') {
            yield 'fa-input-text';
        }
        if (state?.view === 'Pages') {
            yield 'fa-file-invoice';
        }
        if (state?.view === 'Apps') {
            yield 'fa-browser';
        }
    }, [state?.view]);
    const onCreate = () => {
        createDocument().then(newItem => onSelect(newItem)());
    };
    const type = state?.view.replace('s', '').toLocaleLowerCase();
    const VirtualListItem = ({ index, style }) => {
        const item = view.atIndex(index);
        return (React.createElement("div", { key: item?.id, className: "document-list-item", onClick: onSelect(item), style: { ...style, pointerEvents: 'auto' } },
            React.createElement("div", { className: "document-title" },
                React.createElement("i", { className: "fak fa-fw fa-adaptive-document-light" }),
                React.createElement("div", { className: "title" }, item?.title))));
    };
    return (React.createElement("div", { className: "open-dialog" },
        React.createElement("h2", { className: "dialog-title" },
            React.createElement("i", { className: iconClass }),
            "Open ",
            type === 'app' ? 'an' : 'a',
            " ",
            type),
        React.createElement("div", { className: searchClass },
            React.createElement("i", { className: "search-icon fal fa-fw fa-search" }),
            React.createElement("input", { value: term, onChange: onChangeTerm, onKeyDown: onKeyDown, onFocus: onFocus(true), onBlur: onFocus(false), placeholder: "Search by title..." }),
            React.createElement("button", { className: "clear-search", onClick: onClearSearch },
                React.createElement("i", { className: "clear-search-icon fal fa-fw fa-times" }))),
        React.createElement("div", { className: "document-list" },
            !!view.$state.notFound && (React.createElement("div", { className: "no-documents-found" },
                React.createElement("i", { className: "fal fa-fw fa-file-magnifying-glass" }),
                React.createElement("h3", { className: "title" }, "No documents found"),
                React.createElement("p", { className: "sub-title" }, "Unfortunatley we couldn't find any documents matching that search term. You can try another search term, or create a new document."),
                React.createElement(CreationWizard, { type: type }))),
            view.$state.fetching && !view.$state.notFound && (React.createElement("div", { className: "loading-documents" },
                React.createElement("h3", { className: "title" },
                    React.createElement("i", { className: "fad fa-fw fa-spinner-third fa-spin" }),
                    "Loading documents..."))),
            React.createElement(List, { height: 500, width: 490, itemCount: view.length(), itemSize: 35 }, VirtualListItem),
            view.$state.notFound),
        React.createElement("div", { className: "actions" },
            React.createElement("button", { className: "close", onClick: onCancel }, "Close"),
            !!view.$state.notFound && (React.createElement("button", { className: "create", onClick: onCreate }, "Create")))));
}
