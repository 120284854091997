import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React, { useEffect } from 'react';
import useDocument, { getDocumentLinks } from '../../store/designer-store';
export default function WorkspaceItem({ id, activeDocument, onSelectDocument, }) {
    const [doc, proxy] = useDocument(id);
    useEffect(() => {
        proxy.get();
        getDocumentLinks(id);
    }, []);
    const documentClass = useComposedClassName(function* () {
        yield 'document-card';
        if (id === activeDocument) {
            yield 'active';
        }
        if (!!doc.$state.dirty) {
            yield 'dirty';
        }
    }, [doc.$state.dirty, id, activeDocument]);
    return (React.createElement("div", { key: id, title: doc.title, className: documentClass, onClick: onSelectDocument(id) },
        React.createElement("span", { className: "title" }, doc.title)));
}
