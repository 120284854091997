import React from 'react';
export default function RoutesIndex() {
    return (React.createElement("div", { className: "routes-index" },
        React.createElement("i", { className: "fak fa-fw fa-adaptive-document-light" }),
        React.createElement("span", { className: "title" }, "Adaptive Document Designer"),
        React.createElement("span", { className: "sub-title" },
            "Select a element from the tree in the left hand panel to begin editing",
            React.createElement("br", null),
            "or select a configure page to begin configuring.")));
}
