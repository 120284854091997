import { useSnapshot } from 'valtio';
import Designer from '../components/designer/designer';
import openDialog from '../components/dialog/open-dialog';
import Overview from '../components/overview/overview';
import Preview from '../components/preview/preview';
import StateDashboard from '../components/state/state-dashboard';
import TabComponent from '../components/tabs/tab-component';
import { toast } from 'react-toastify';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { store } from '@rapid/sdk';
const saveActiveItem = () => {
    const item = getListItem('Adaptive Documents', store.applicationData.activeDocument);
    item.save();
    toast.success('Document saved');
};
const saveAllItems = () => {
    for (const id of store.applicationData.workspace ?? []) {
        getListItem('Adaptive Documents', +id).save();
    }
    // TODO The count should only show the DIRTY documents
    // and they are the only ones that should be saved
    const count = store.applicationData.workspace?.length;
    if (count > 1) {
        toast.success(`${count} Documents saved`);
    }
    else {
        toast.success(`${count} Document saved`);
    }
};
const toolbar = [
    {
        type: 'item',
        key: 'save',
        iconClass: 'fal fa-fw fa-floppy-disk',
        leaf: true,
        label: 'Save',
        onClick(e) {
            if (!e.item.disabled) {
                saveActiveItem();
            }
        },
    },
    {
        type: 'item',
        key: 'save-all',
        iconClass: 'fal fa-fw fa-floppy-disks',
        leaf: true,
        label: 'Save all',
        onClick(e) {
            if (!e.item.disabled) {
                saveAllItems();
            }
        },
    },
    {
        type: 'item',
        key: 'delete',
        iconClass: 'fal fa-fw fa-trash-can',
        leaf: true,
        label: 'Delete',
        onClick(e) {
            if (!e.item.disabled) {
                openDialog('delete', () => { }, () => { });
            }
        },
    },
];
const farToolbar = [];
const application = [
    {
        type: 'item',
        key: 'file',
        label: 'File',
        children: [
            {
                type: 'item',
                key: 'new-form',
                iconClass: 'fal fa-fw fa-pen-field',
                label: 'New form',
                info: 'ctrl + d',
                leaf: true,
                onClick(click) {
                    store.applicationData.documentDialog.view = 'Forms';
                    const onCancel = () => {
                        console.log('Canceled');
                    };
                    const onSave = (item) => {
                        click.addTab(`${item}/design`, true);
                    };
                    openDialog('create', onSave, onCancel);
                },
            },
            {
                type: 'item',
                key: 'new-page',
                iconClass: 'fal fa-fw fa-file-circle-plus',
                label: 'New page',
                leaf: true,
                onClick(click) {
                    store.applicationData.documentDialog.view = 'Pages';
                    const onCancel = () => {
                        console.log('Canceled');
                    };
                    const onSave = (item) => {
                        click.addTab(`${item}/design`, true);
                    };
                    openDialog('create', onSave, onCancel);
                },
            },
            {
                type: 'separator',
                key: 'open-create-sep',
                leaf: true,
            },
            {
                type: 'item',
                key: 'open-form',
                iconClass: 'fal fa-fw fa-input-text',
                label: 'Open form',
                leaf: true,
                info: 'ctrl + o',
                onClick(click) {
                    store.applicationData.documentDialog.view = 'Forms';
                    const onCancel = () => {
                        console.log('Canceled');
                    };
                    const onSave = (item) => {
                        click.addTab(`${item}/design`, true);
                    };
                    openDialog('open', onSave, onCancel);
                },
            },
            {
                type: 'item',
                key: 'open-page',
                iconClass: 'fal fa-fw fa-file-invoice',
                label: 'Open page',
                leaf: true,
                onClick(click) {
                    store.applicationData.documentDialog.view = 'Pages';
                    const onCancel = () => {
                        console.log('Canceled');
                    };
                    const onSave = (item) => {
                        click.addTab(`${item}/design`, true);
                    };
                    openDialog('open', onSave, onCancel);
                },
            },
            {
                type: 'separator',
                key: 'create-clone-sep',
                leaf: true,
            },
            {
                type: 'item',
                key: 'clone',
                iconClass: 'fal fa-fw fa-copy',
                label: 'Clone active document',
                leaf: true,
                onClick(click) {
                    const item = getListItem('Adaptive Documents', store.applicationData.activeDocument);
                    if (item.document.type === 'page') {
                        store.applicationData.documentDialog.view = 'Pages';
                    }
                    else {
                        store.applicationData.documentDialog.view = 'Forms';
                    }
                    const onCancel = () => {
                        console.log('Canceled');
                    };
                    const onSave = (item) => {
                        click.addTab(`${item}/design`, true);
                    };
                    openDialog('clone', onSave, onCancel);
                },
            },
        ],
    },
];
const farApplication = [
    {
        type: 'item',
        key: 'dark-mode',
        iconClass: 'fal fa-fw fa-sun-bright',
        leaf: true,
        onClick() {
            toggleTheme();
        },
    },
];
export const initialStore = {
    keyboardShortcuts: {
        'ctrl+o': () => {
            const onCancel = () => {
                console.log('Canceled');
            };
            const onSave = (item) => {
                console.log(item);
            };
            openDialog('open', onSave, onCancel);
        },
        'ctrl+d': () => {
            const onCancel = () => {
                console.log('Canceled');
            };
            const onSave = (item) => {
                console.log(item);
            };
            openDialog('create', onSave, onCancel);
        },
        'ctrl+s': () => {
            const [, , , , documentID] = location.pathname
                .replace(/^\//, '')
                .split('/');
            if (typeof documentID === 'number') {
                saveActiveItem();
            }
        },
        'ctrl+shift+s': () => {
            const [, , , , documentID] = location.pathname
                .replace(/^\//, '')
                .split('/');
            if (typeof documentID === 'number') {
                saveAllItems();
            }
        },
    },
    routes: {
        ':type/:id/design': {
            key: 'design',
            component: Designer,
            tabComponent: TabComponent,
        },
        ':type/:id/overview': {
            key: 'overview',
            component: Overview,
            tabComponent: TabComponent,
        },
        ':type/:id/state/:state': {
            key: 'state/:state',
            component: StateDashboard,
            tabComponent: TabComponent,
        },
        ':type/:id/preview': {
            key: 'preview',
            component: Preview,
            tabComponent: TabComponent,
        },
    },
    menus: {
        toolbar,
        farToolbar,
        application,
        farApplication,
    },
    theme: 'dark-theme',
    documents: {},
    activeDocument: undefined,
    documentsListVisible: true,
    documentDialog: {
        view: 'Forms',
        searchHasFocus: true,
    },
};
export function useTheme() {
    const snap = useSnapshot(store.applicationData);
    return [snap.theme, snap.theme === 'dark-theme'];
}
export function setTheme(theme) {
    store.applicationData.theme = theme;
    changeDomTheme(theme);
}
export function toggleTheme() {
    if (store.applicationData.theme === 'dark-theme') {
        setTheme('light-theme');
        return;
    }
    if (store.applicationData.theme === 'light-theme') {
        setTheme('dark-theme');
        return;
    }
}
const THEME_KEY = 'adaptive-designer:theme';
export function getLocalStorageTheme() {
    // Get the theme from local storage
    const currentTheme = localStorage.getItem(THEME_KEY);
    // Check if it's set and use it if it is
    if (typeof currentTheme === 'string') {
        changeDomTheme(currentTheme);
        store.applicationData.theme = currentTheme;
    }
}
function changeDomTheme(theme) {
    const html = document.querySelector('html');
    // Make sure we have html
    if (!html) {
        return;
    }
    // Check if the theme is already set
    if (html.classList.contains(theme)) {
        return;
    }
    // Set the theme to the appropriate theme
    if (theme === 'dark-theme') {
        html.classList.add('dark-theme');
        html.classList.remove('light-theme');
    }
    else {
        html.classList.add('light-theme');
        html.classList.remove('dark-theme');
    }
    // Add the current theme to localStorage
    localStorage.setItem(THEME_KEY, theme);
}
