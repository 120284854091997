import { DialogEvent, } from '@rapid/convergence/lib/Dialog/DialogEventListener';
import Dialog from './dialog';
export default function openDialog(type, onSave, onCancel) {
    const event = new DialogEvent((Dialog), {
        onCancel,
        onSave,
        type,
    });
    document.dispatchEvent(event);
}
