import React from 'react';
import { useStateLink } from '../../store/designer-store';
import DashboardCard from '../dashboard-card/dashboard-card';
import LinkDetails from '../overview/cards/states/link-details';
import StateOverviewLabel from './state-overview-label';
export default function StateOverview({ state, stateKey, documentProxy, }) {
    const { link, generateLink, deleteLink, url, openLink, copyLink } = useStateLink(stateKey);
    const onGenerateLink = () => {
        generateLink().then(() => {
            documentProxy.save();
        });
    };
    return (React.createElement(DashboardCard, { label: React.createElement(StateOverviewLabel, { link: link, state: state, stateKey: stateKey, onGenerateLink: onGenerateLink }), iconClass: "far fa-fw fa-rectangle-history", className: "state-overview" },
        !!link && (React.createElement(LinkDetails, { state: state, stateKey: stateKey, link: link, url: url, onOpenLink: openLink, onCopyLink: copyLink, onDeleteLink: deleteLink })),
        !link && (React.createElement("div", { className: "no-link" },
            React.createElement("button", { className: "generate-link", onClick: generateLink },
                React.createElement("i", { className: "generate-link-icon fal fa-fw fa-share-nodes" }),
                "Share ",
                state?.label)))));
}
