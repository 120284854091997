import { AdaptiveRenderer, AdaptiveStore, useRendererId, } from '@rapid/adaptive-framework';
import React, { useEffect } from 'react';
import DashboardCard from '../dashboard-card/dashboard-card';
import { useSnapshot } from 'valtio';
export default function StateEditor({ stateKey, documentProxy, }) {
    const rendererId = useRendererId(`state-editor:${stateKey}`);
    /*
     * NOTE: Hello concerned citizen.
     *
     * I see you've noticed that the adaptive renderer
     * below us doesn't have an onDataChange function.
     *
     * How observant of you. . .
     *
     * If you're very observant you will have noticed
     * that interacting with the form in the ui dirties
     * the document by changing `document.head.states[CURRENT_STATE].state`
     *
     * But how you might ask!?!?
     *
     * There isn't an onDataChange function
     * as you so astutely pointed out earlier.
     *
     * We have valtio to thank for this genuine magic
     * the secret lies in the `@rapid/adaptive-framework` package
     * in the file `use-adaptive-metadata.ts`. On line 21 you'll find
     *
     *
     * 21: AdaptiveStore[rendererId].state = document.head.states[state].state;
     *
     * the `document` referenced there is `documentProxy`
     * we've passed in below. We are building
     * a direct link between the `AdaptiveStore`
     * and the adaptive document on the SDK item.
     * So every change to the AdaptiveStore state will
     * bubble up from to the document in the item.
     **/
    const docSnap = useSnapshot(documentProxy);
    useEffect(function stateToDocumentBridge() {
        const state = documentProxy?.document?.head?.states?.[stateKey]?.state;
        !AdaptiveStore[rendererId]?.state
            ? (AdaptiveStore[rendererId] = { state })
            : (AdaptiveStore[rendererId].state = state);
    }, [stateKey, docSnap?.document?.head]);
    return (React.createElement(DashboardCard, { className: "Body" },
        React.createElement("div", { className: "FormContainer" }, !!docSnap?.document?.body && (React.createElement(AdaptiveRenderer, { document: documentProxy?.document, mode: "normal", rendererId: rendererId })))));
}
