import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { AdaptiveRenderer } from '@rapid/adaptive-framework';
import { useQueryStringProxy, useTabContent } from '@rapid/convergence';
import React from 'react';
import useDocument from '../../store/designer-store';
export default function Preview() {
    const { match } = useTabContent();
    const [doc, docProxy] = useDocument(+match.params.id);
    const qs = useQueryStringProxy();
    const previewClass = useComposedClassName(function* () {
        yield 'preview-container';
        if (qs.preview) {
            yield qs.preview;
        }
        else {
            yield 'rpform';
        }
    }, [qs.preview]);
    return (React.createElement("div", { className: previewClass },
        React.createElement("div", { className: "preview-document" },
            qs.preview === 'explorer-action' && React.createElement("div", { className: "action-arrow" }),
            !!doc?.document?.body && (React.createElement(AdaptiveRenderer, { document: docProxy.document, mode: "normal", rendererId: `preview:${doc.id}` })),
            qs.preview === 'explorer-action' && (React.createElement("div", { className: "action-buttons" },
                React.createElement("div", { className: "action-button submit" },
                    React.createElement("i", { className: "fal fa-fw fa-check" }),
                    "Sumbit"),
                React.createElement("div", { className: "action-button clear" }, "Clear"),
                React.createElement("div", { className: "action-button cancel" },
                    React.createElement("i", { className: "fal fa-fw fa-times" }),
                    "Cancel"))))));
}
