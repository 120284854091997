import React from 'react';
import DashboardCard from '../../dashboard-card/dashboard-card';
export default function ActivityFeed() {
    return (React.createElement(DashboardCard, { label: "Document Activity", iconClass: "far fa-fw fa-messages", subTitle: "The activity feed for the document", className: "Activity" },
        React.createElement("div", { className: "usage-stats" },
            React.createElement("span", { className: "usage-title" },
                "Activity Feed",
                React.createElement("i", { className: "fad fa-fw fa-messages" })),
            React.createElement("div", { className: "usage-description" }, "This will be the activity feed for this document list item"))));
}
