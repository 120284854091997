import { useQueryStringProxy } from '@rapid/convergence';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import addTab from '../../utils/add-tab';
import { useApplicationStore } from '@rapid/sdk';
import WorkspaceItem from './workspace-item';
import { setActiveDocument } from '../../store/designer-store';
export default function DocumentsList() {
    const qs = useQueryStringProxy();
    const location = useLocation();
    const nav = useNavigate();
    const [appSnap] = useApplicationStore();
    const onSelectDocument = (id) => () => {
        const query = addTab(qs, `documents/${id}/design`);
        if (!location.pathname.includes(id.toString())) {
            nav({
                pathname: `documents/${id}/design`,
                search: query.toString(),
            });
        }
        setActiveDocument(id);
    };
    return (React.createElement("div", { className: "documents-list" },
        React.createElement("div", { className: "scroll-container" }, appSnap.workspace?.map(id => (React.createElement(WorkspaceItem, { key: id, onSelectDocument: onSelectDocument, id: id, activeDocument: appSnap.activeDocument }))))));
}
