import { AdaptiveRenderer, defaultFormDocument, } from '@rapid/adaptive-framework';
import { getListByName, store } from '@rapid/sdk';
import jsonpath from 'jsonpath';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { proxy, useSnapshot } from 'valtio';
export default function CategoryCard({ category, app, item: _item, itemProxy, }) {
    const [store, onChange, onPlacePage] = useCategoryCardStore(category, app, itemProxy);
    const snap = useSnapshot(store);
    const disabled = !category.required.every(key => !!snap.configuration[key]);
    return (React.createElement("div", { className: "category-card", key: category.id },
        React.createElement("h4", { className: "title" }, category.title),
        React.createElement("p", { className: "desc" }, category.description),
        React.createElement(AdaptiveRenderer, { document: store.document, onDataChange: onChange }),
        React.createElement("button", { className: "place-page", disabled: disabled, onClick: onPlacePage }, "Place page")));
}
function useCategoryCardStore(category, app, item) {
    const catStore = useMemo(() => proxy({
        document: defaultFormDocument,
        configuration: {},
    }), [category.id]);
    const onChange = (element, data) => {
        if (!!element.dataPath) {
            jsonpath.value(catStore.configuration, element.dataPath, data);
        }
    };
    if (!!category.schema && catStore.document.body.id !== category.schema?.id) {
        catStore.document.body = category.schema;
    }
    const onPlacePage = () => {
        if (category.required.every(key => !!catStore.configuration[key])) {
            const list = getListByName(catStore.configuration.list);
            const newAppDocument = {
                adaptive_app_id: app.id,
                adaptive_document_id: item.id,
                entity_type_id: list.TypeId,
                category: category.id,
            };
            if (item.$data.pages.some((ad) => newAppDocument.adaptive_document_id === ad.adaptive_document_id &&
                newAppDocument.adaptive_app_id === ad.adaptive_app_id &&
                newAppDocument.entity_type_id === ad.entity_type_id &&
                newAppDocument.category === ad.category)) {
                toast.warn('A page placement matching this configuration already exists');
                return;
            }
            toast
                .promise(store.api.apps.postJson({}, {
                adaptive_app_id: app.id,
                adaptive_document_id: item.id,
                entity_type_id: list.TypeId,
                category: category.id,
            }), {
                pending: 'Adding app document...',
                success: 'App document created!',
                error: 'An error occured',
            })
                .then(() => {
                item.getDataArray('pages', store.api.apps.documents[item.id]);
            });
        }
        else {
            toast.warn(`The following fields are required: ${category.required.join(',')}`);
        }
    };
    return [catStore, onChange, onPlacePage];
}
