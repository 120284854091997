import React, { useMemo } from 'react';
import DashboardCard from '../../../dashboard-card/dashboard-card';
import AddState from './add-state';
import State from './state';
export default function States({ item }) {
    const states = useMemo(() => Object.keys(item?.document?.head?.states ?? {}), [item?.document?.head?.states]);
    return (React.createElement(DashboardCard, { label: "States", iconClass: "far fa-fw fa-rectangle-history", subTitle: "The states currently configured in this document", className: "States" },
        states.map(stateKey => (React.createElement(State, { states: states, state: item?.document?.head?.states[stateKey], stateKey: stateKey, key: stateKey, isDefaultState: stateKey === 'default' }))),
        React.createElement(AddState, null)));
}
