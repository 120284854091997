import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { DesignerLayout } from '@rapid/convergence';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Logo from './components/logo';
import Workspace from './components/workspace/workspace';
import { useApplicationStore } from '@rapid/sdk';
import RoutesIndex from './components/routes-index';
import { useElementToMenuElement } from './utils/recursivley-derive-element-tree';
import useDocument from './store/designer-store';
import { useOnTreeChange } from './utils/use-on-tree-change';
import useSelectedId from './utils/use-selected-id';
export default function DesignSurface() {
    const [store] = useApplicationStore();
    const params = useParams();
    const id = isNaN(+params.id) ? store.activeDocument : +params.id;
    const [docSnap, docProxy] = useDocument(id);
    const selectedId = useSelectedId(params.id);
    const gridClass = useComposedClassName(function* () {
        yield 'grid-container';
        if (!!store.documentsListVisible && !!store.activeDocument) {
            yield 'visible';
        }
    }, [store.documentsListVisible, store.activeDocument]);
    const toolbar = useMemo(() => {
        return store.menus.toolbar?.map((item) => ({
            ...item,
            disabled: !params.id,
        }));
    }, [store.menus.toolbar.length, params.id]);
    const tree = useElementToMenuElement(docProxy?.document?.body, params.id, `documents/${docProxy?.id}/design`, [params.id, docSnap?.document?.body]);
    const onTreeChange = useOnTreeChange(docProxy, [docSnap]);
    return (React.createElement("span", { className: gridClass },
        React.createElement(DesignerLayout, { keyboardShortcuts: store.keyboardShortcuts, routes: store.routes, menubar: store.menus.application, farMenubar: store.menus.farApplication, toolbar: toolbar, farToolbar: store.menus.farToolbar, sidebar: params.id ? tree : undefined, sidebarProps: {
                selectedId,
                onTreeChange,
            }, logo: React.createElement(React.Fragment, null,
                React.createElement(Logo, null),
                React.createElement(Workspace, null)) }, !params?.id && !params.type && React.createElement(RoutesIndex, null))));
}
