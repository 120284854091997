import { useQueryStringProxy } from '@rapid/convergence';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
export default function useCloseTab(match) {
    const qs = useQueryStringProxy();
    const navigate = useNavigate();
    const closeTab = useCallback((ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        // Get the tabs from the query string proxy
        let tabs = qs.tabs.split(',');
        // Used in tabs.findIndex
        const findInd = (tabPath) => {
            // Check if the tabPath has a leading /
            if (!tabPath.match(/^\//)) {
                // if not add one so the pathname matches
                tabPath = `/${tabPath}`;
            }
            // Check if the pathname matches
            const matches = tabPath === match.pathname;
            // Return the bool match
            return matches;
        };
        // Find the current tabs index
        const index = tabs.findIndex(findInd) ?? -1;
        // if not found return
        if (index === -1)
            return;
        // if found splice the tab out of tabs collection
        tabs.splice(index, 1);
        let pathname;
        // Build the new pathname
        if (location.pathname.includes(match.pathname)) {
            pathname = location.pathname.replace(match.pathname, '');
        }
        // Add the new active tab
        if (!!tabs.length) {
            let tab;
            // If there is only one tab use that
            if (tabs.length === 1) {
                tab = tabs[0];
            }
            // If it doesn't exist
            if (!tab) {
                // Try the tab to the left
                tab = tabs[index - 1];
            }
            // If it doesn't exist
            if (!tab) {
                // Try the tab to the right
                tab = tabs[index + 1];
            }
            // Add it to the path name
            if (pathname) {
                pathname = pathname.concat('/', tab);
            }
        }
        // Joing the tabs back into the query string
        qs.tabs = tabs.join(',');
        // navigate to path with query string
        navigate({
            pathname: pathname ?? location.pathname,
            search: qs.toString(),
        });
    }, [match, qs]);
    return closeTab;
}
export function useCloseDocumentTabs(item) {
    const qs = useQueryStringProxy();
    const navigate = useNavigate();
    const closeTab = useCallback((ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        // Get the tabs from the query string proxy
        let tabs = qs.tabs.split(',');
        // Used in tabs.findIndex
        const findInd = (tabPath) => {
            // Check if the tabPath has a leading /
            if (!tabPath.match(/^\//)) {
                // if not add one so the pathname matches
                tabPath = `/${tabPath}`;
            }
            // Check if the pathname matches
            const matches = tabPath.includes(`documents/${item.id}`);
            // Return the bool match
            return matches;
        };
        // Find the current tabs index
        const docTabs = tabs.filter(findInd);
        if (docTabs.length === 0) {
            return;
        }
        // if found splice the tab out of tabs collection
        tabs = tabs.filter((val) => !docTabs.includes(val));
        let pathname;
        const match = `\/documents\/${item.id}.*$`;
        const matchRegex = new RegExp(match);
        // Build the new pathname
        if (matchRegex.test(location.pathname)) {
            pathname = location.pathname.replace(matchRegex, '');
        }
        // Add the new active tab
        if (!!tabs.length) {
            const tab = tabs[0];
            // Add it to the path name
            if (pathname) {
                pathname = pathname.concat('/', tab);
            }
        }
        // Joing the tabs back into the query string
        qs.tabs = tabs.join(',');
        // navigate to path with query string
        navigate({
            pathname: pathname ?? location.pathname,
            search: qs.toString(),
        });
    }, [item, qs]);
    return closeTab;
}
