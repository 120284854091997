import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { useApplicationStore } from '@rapid/sdk';
import React from 'react';
import useDocument from '../../store/designer-store';
import CreationWizard, { cloneDocument, } from '../creation-wizard/creation-wizard';
import { useDocumentDialogState } from './use-dialog-state';
export default function CloneDialog({ onSave, onCancel }) {
    const onClone = () => {
        cloneDocument().then(newItem => onSave(`documents/${newItem.id}`));
    };
    const [appData] = useApplicationStore();
    const [item] = useDocument(appData.activeDocument);
    const [{ view }] = useDocumentDialogState();
    const iconClass = useComposedClassName(function* () {
        yield 'fal fa-fw fa-copy';
    }, [view]);
    const type = view.replace('s', '').toLocaleLowerCase();
    return (React.createElement("div", { className: "create-dialog" },
        React.createElement("h2", { className: "dialog-title" },
            React.createElement("i", { className: iconClass }),
            "Clone ",
            item.title),
        React.createElement("div", { className: "description" },
            "Create a clone of ",
            item.title,
            " with a new name."),
        React.createElement(CreationWizard, { type: type }),
        React.createElement("div", { className: "actions" },
            React.createElement("button", { className: "close", onClick: onCancel }, "Close"),
            React.createElement("button", { className: "create", onClick: onClone }, "Clone"))));
}
