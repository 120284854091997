import { nanoid } from 'nanoid';
/**
 * Ensures generation of a unique id by checking against
 * a list of known id's and regernarting if a collision is found
 *
 * @param {Record<string, any> | string[]} currentIds - A collection of known ids to check for collisions
 * @param {number} [length] - An optional length to pass to nanoid
 * */
export default function generateUniqueNanoid(currentIds, length) {
    let id = nanoid(length);
    if (Array.isArray(currentIds)
        ? currentIds.some(i => i === id)
        : !!currentIds[id]) {
        id = generateUniqueNanoid(currentIds, length);
    }
    return id;
}
