import { useTabContent } from '@rapid/convergence';
import React from 'react';
import useDocument, { useDocumentState } from '../../store/designer-store';
import StateEditor from './state-editor';
import StateOverview from './state-overview';
export default function StateDashboard() {
    const { match } = useTabContent();
    const state = useDocumentState(+match.params.id, match.params.state);
    const [, documentProxy] = useDocument(+match.params.id);
    return (React.createElement("div", { className: "state-dashboard" },
        React.createElement(StateOverview, { state: state, stateKey: match.params.state, documentProxy: documentProxy }),
        React.createElement(StateEditor, { stateKey: match.params.state, documentProxy: documentProxy })));
}
