import { useApplicationStore } from '@rapid/sdk';
import React from 'react';
import ActiveDocument from './active-document';
import DocumentsList from './documents-list';
export default function Workspace() {
    const [snap, store] = useApplicationStore();
    const toggleList = (forceState) => {
        if (forceState !== undefined) {
            store.documentsListVisible = forceState;
        }
        store.documentsListVisible = !store.documentsListVisible;
    };
    const showDocumentsList = !!snap.documentsListVisible && !!snap.activeDocument;
    return (React.createElement("div", { className: "workspace" },
        React.createElement(ActiveDocument, { documentId: snap.activeDocument, listVisible: snap.documentsListVisible, toggleList: toggleList }),
        showDocumentsList && React.createElement(DocumentsList, null)));
}
