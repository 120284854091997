import { AdaptiveRenderer, defaultFormDocument, } from '@rapid/adaptive-framework';
import React from 'react';
import DashboardCard from '../../dashboard-card/dashboard-card';
import { formDetailsDocument, pageDetailsDocument } from './details-schema';
import jsonpath from 'jsonpath';
export default function DocumentDetails({ itemProxy: itemProxy, item: item, }) {
    const onDataChange = (element, data, path, key = 'data') => {
        if (!itemProxy.$state.fetching && !itemProxy.document) {
            itemProxy.document = defaultFormDocument;
        }
        if (key === 'data' && element.dataPath) {
            jsonpath.value(itemProxy, element.dataPath, data);
        }
    };
    return (React.createElement(DashboardCard, { label: "Details", iconClass: "fak fa-fw fa-adaptive-document-light", subTitle: "Overview of the document details", className: "Details" },
        React.createElement("div", { className: "FormDetails" },
            React.createElement(AdaptiveRenderer, { onDataChange: onDataChange, data: item, rendererId: `document-details:${item.id}`, document: item.document?.type === 'page'
                    ? pageDetailsDocument
                    : formDetailsDocument, mode: "edit" }))));
}
