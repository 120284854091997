import React from 'react';
import { useNavigate } from 'react-router-dom';
export default function Logo() {
    const nav = useNavigate();
    const onBackToSelection = () => {
        nav('/');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "logo-container", title: "Return to document selection" },
            React.createElement("button", { className: "back-button", onClick: onBackToSelection },
                React.createElement("i", { className: "fal fa-fw fa-chevron-left button-icon" })),
            React.createElement("img", { className: "logo-icon", src: "data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPScxLjInIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDE2MyAxODQnIHdpZHRoPScxNjMnIGhlaWdodD0nMTg0Jz48cGF0aCBmaWxsPScjMjQ5OWQ1JyBpZD0nUGF0aCA3OCcgZmlsbC1ydWxlPSdldmVub2RkJyBjbGFzcz0nczAnIGQ9J20xNjIuNiAxMzcuOGwtODEuMiA0NS44LTgxLjItNDUuOGgwLjF2LTkxLjZsODEuMS00NS44IDgxLjIgNDUuOHptLTE4LjItODEuM2wtNjMtMzUuNi02Mi45IDM1LjZ2NzFsNjIuOSAzNS41IDYzLTM1LjV6bS01My45IDQwLjd2NDAuN2gtMTguMnYtNTAuOWgwLjFsLTAuMS0wLjEgNDUuMS0yNS41IDkuMSAxNS41eicvPjwvc3ZnPg==" }),
            React.createElement("h1", { className: "app-title" }, "Adaptive Designer"))));
}
