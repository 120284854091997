import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { useTabContent } from '@rapid/convergence';
import React from 'react';
import useDocument from '../../store/designer-store';
import Usage from '../usage/usage';
import ActivityFeed from './cards/activity-feed';
import DocumentDetails from './cards/document-details';
import States from './cards/states/states';
export default function Overview() {
    const { match } = useTabContent();
    const [item, itemProxy] = useDocument(+match.params.id);
    const type = item?.document?.type;
    const overviewClass = useComposedClassName(function* () {
        yield 'overview';
        yield type;
    }, [type]);
    return (React.createElement("div", { className: overviewClass },
        React.createElement(DocumentDetails, { item: item, itemProxy: itemProxy }),
        type === 'page' && React.createElement(Usage, { item: item, itemProxy: itemProxy }),
        type === 'form' && React.createElement(States, { item: item }),
        React.createElement(ActivityFeed, null)));
}
