// import { useRapidApplication } from '@rapid/sdk';
import { store } from '@rapid/sdk';
import { useMemo } from 'react';
import { useSnapshot } from 'valtio';
/**
 * Generates the complete rpform.io url for the provided public for link
 *
 * @params {string} link - Hashed public form id to generate link for
 * @returns {string} the full url to open the public form in rpforms
 */
export default function useRpformUrl(link) {
    const snap = useSnapshot(store);
    const url = useMemo(function generateRpformUrl() {
        const env = snap.auth.env;
        return `${snap.auth.environments[env].rpform_url}/${link}`;
    }, [snap.auth.env, snap.auth.environments, link]);
    return url;
}
