import { openActionMenu } from '@rapid/adaptive-designer';
import { setEditingId } from '@rapid/adaptive-framework';
import { useMemo } from 'react';
import { proxy } from 'valtio';
import ElementTitleRender from '../components/sidebar/element-title-render';
export function useElementToMenuElement(element, rendererId, designTab, deps) {
    // NOTE: I don't think we need to derive unless we want to change the value
    const recurseTree = (element, menu) => {
        if (Array.isArray(element?.$children)) {
            if (!Array.isArray(menu.children)) {
                menu.children = [];
            }
            for (const [ind, child] of element.$children.entries()) {
                if (!menu.children[ind]) {
                    menu.children[ind] = {};
                }
                recurseTree(child, menu.children[ind]);
            }
        }
        menu.key = element.id;
        menu.type = 'item';
        menu.label =
            element.title ??
                element.attributes?.label ??
                element.attributes?.title ??
                element.$type ??
                'untitled';
        menu.leaf = !element.$type.includes('Layout');
        menu.iconClass = 'fad fa-fw fa-question';
        menu.element = element;
        menu.titleRender = ElementTitleRender;
        menu.onContextMenu = (e) => {
            e.event.preventDefault();
            e.event.stopPropagation();
            openActionMenu(rendererId, element)(e.event);
        };
        menu.onClick = (e) => {
            e.event.stopPropagation();
            e.event.preventDefault();
            e.addTab(designTab);
            setEditingId(rendererId, element.id);
        };
    };
    const tree = useMemo(function buildTree() {
        const innerTree = proxy([]);
        if (!!element) {
            if (!innerTree[0]) {
                innerTree[0] = {};
            }
            recurseTree(element, innerTree[0]);
        }
        return innerTree;
    }, deps);
    return tree;
}
