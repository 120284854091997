import React from 'react';
import EditableTitle from '../editable-title/editable-title';
import CategoryCard from './category-card';
import PagePlacement from './page-placement-card';
export default function AppCard({ app, appProxy, item, itemProxy }) {
    const onTitleChange = (e) => {
        appProxy.title = e.target.value;
    };
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
        }
    };
    const onTitleClick = () => { };
    return (React.createElement("div", { className: "app-card" },
        React.createElement(EditableTitle, { title: app.title, onTitleChange: onTitleChange, onKeyDown: onKeyDown, onTitleClick: onTitleClick, iconClass: app.document?.head?.iconClass }),
        React.createElement("div", { className: "app-content" },
            React.createElement("div", { className: "page-slots" }, app.document?.head.categories.map((category) => (React.createElement(CategoryCard, { key: category.id, category: category, app: app, item: item, itemProxy: itemProxy })))),
            React.createElement("div", { className: "page-placements" }, item.$data.pages?.map((page) => (React.createElement(PagePlacement, { key: page.id, item: item, itemProxy: itemProxy, appProxy: appProxy, app: app, page: page, categories: app.document?.head.categories })))))));
}
