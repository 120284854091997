import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React from 'react';
export default function DashboardCard({ label, iconClass, subTitle, className, children, }) {
    const dashboardCardClass = useComposedClassName(function* () {
        yield 'DashboardCard';
        if (!!className)
            yield className;
        if (!label && !subTitle) {
            yield 'empty-card';
        }
    }, [className, label, subTitle]);
    return (React.createElement("div", { className: dashboardCardClass },
        React.createElement("div", { className: "DashboardCardHeader" },
            !!label && (React.createElement("div", { className: "DashboardCardHeaderTitle" },
                !!iconClass && React.createElement("i", { className: iconClass }),
                "\u2002",
                label)),
            !!subTitle && (React.createElement("div", { className: "DashboardCardHeaderSubtitle" }, subTitle))),
        React.createElement("span", { className: "DashboardCardBody" },
            React.createElement("span", { className: "DashboardCardBodyScroll" }, children))));
}
