import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { useQueryStringProxy, useTabContent } from '@rapid/convergence';
import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useDocument from '../../../../store/designer-store';
import generateUniqueNanoid from '../../../../utils/generate-unique-nanoid';
export default function AddState({}) {
    const { match } = useTabContent();
    const [, documentProxy] = useDocument(+match.params.id);
    const qs = useQueryStringProxy();
    const [, setQuery] = useSearchParams();
    const inputRef = useRef(null);
    const [hasFocus, setHasFocus] = useState(false);
    const onFocus = (focus) => () => {
        setHasFocus(focus);
    };
    const [stateTitle, setStateTitle] = useState('');
    const onTitleChange = (e) => {
        setStateTitle(e.target.value);
    };
    const clearStateTitle = () => {
        setStateTitle('');
        setHasFocus(false);
    };
    const addState = async () => {
        const isEmpty = !stateTitle;
        if (isEmpty) {
            return;
        }
        qs.state = stateTitle;
        setQuery(qs.toString());
        if (!documentProxy)
            return;
        if (!documentProxy.document)
            return;
        if (!documentProxy.document.head)
            return;
        if (!documentProxy.document.head.states) {
            documentProxy.document.head.states = {};
        }
        let id = generateUniqueNanoid(documentProxy.document.head.states, 4);
        if (!documentProxy.document.head.states[id]) {
            documentProxy.document.head.states[id] = {
                label: stateTitle,
                state: {},
            };
        }
        clearStateTitle();
    };
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            addState();
        }
        if (e.key === 'Escape') {
            clearStateTitle();
        }
    };
    const addStateClass = useComposedClassName(function* () {
        yield 'add-state';
        if (hasFocus) {
            yield 'focus';
        }
    }, [hasFocus]);
    return (React.createElement("div", { className: addStateClass, onMouseOver: onFocus(true), onMouseOut: onFocus(false) },
        React.createElement("i", { className: "add-state-icon fak fa-fw fa-rectangle-history-plus" }),
        React.createElement("input", { ref: inputRef, className: "add-state-input", value: stateTitle, onChange: onTitleChange, onKeyDown: onKeyDown, placeholder: "Add new state..." }),
        React.createElement("div", { className: "actions" },
            React.createElement("button", { className: "plus", onClick: addState },
                React.createElement("i", { className: "plus-icon fal fa-fw fa-plus" })),
            React.createElement("button", { className: "cancel", onClick: clearStateTitle },
                React.createElement("i", { className: "cancel-icon fal fa-fw fa-times" })))));
}
