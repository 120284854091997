import React from 'react';
import AdaptiveDesigner from '@rapid/adaptive-designer';
import { useTabContent } from '@rapid/convergence';
import useDocument from '../../store/designer-store';
export default function Designer() {
    const { match } = useTabContent();
    const [doc, docProxy] = useDocument(+match.params.id);
    const saveDocument = () => { };
    return (React.createElement("div", { className: "Designer" }, !!docProxy?.document && (React.createElement(AdaptiveDesigner, { document: docProxy?.document, mode: "design", saveDocument: saveDocument, useExternalDndProvider: true, rendererId: doc.id.toString() }))));
}
