import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React from 'react';
import CreationWizard, { createDocument, } from '../creation-wizard/creation-wizard';
import { useDocumentDialogState } from './use-dialog-state';
export default function CreateDialog({ onSave, onCancel }) {
    const onCreate = () => {
        createDocument().then(newItem => onSave(`documents/${newItem.id}`));
    };
    const [{ view }] = useDocumentDialogState();
    const iconClass = useComposedClassName(function* () {
        yield 'fal fa-fw';
        if (view === 'Forms') {
            yield 'fa-file';
        }
        if (view === 'Pages') {
            yield 'fa-browser';
        }
    }, [view]);
    const description = useComposedClassName(function* () {
        //TODO: Make these descriptions betterer so
        //users can actually know which is for what thing
        if (view === 'Forms') {
            yield 'Forms are used in RPForm to capture anonymous data from clients.';
        }
        if (view === 'Pages') {
            yield 'Pages are used inside RAPID expriences like the Tasks exprience.';
        }
    }, [view]);
    const type = view.replace('s', '').toLocaleLowerCase();
    return (React.createElement("div", { className: "create-dialog" },
        React.createElement("h2", { className: "dialog-title" },
            React.createElement("i", { className: iconClass }),
            "Create a ",
            type),
        React.createElement("div", { className: "description" }, description),
        React.createElement(CreationWizard, { type: type }),
        React.createElement("div", { className: "actions" },
            React.createElement("button", { className: "close", onClick: onCancel }, "Close"),
            React.createElement("button", { className: "create", onClick: onCreate }, "Create"))));
}
