import { store, useViewItems } from '@rapid/sdk';
import React, { useEffect } from 'react';
import AppCard from './app-card';
export default function PageUsage({ item, itemProxy }) {
    const [view, viewProxy] = useViewItems('Adaptive Documents', 'Apps');
    useEffect(() => {
        view.get();
        itemProxy.getDataArray('pages', store.api.apps.documents[item.id]);
    }, []);
    return (React.createElement("div", { className: "page-usage" }, view.map((app, ind) => {
        const appProxy = viewProxy.atIndex(ind);
        return (React.createElement(AppCard, { app: app, appProxy: appProxy, item: item, itemProxy: itemProxy }));
    })));
}
