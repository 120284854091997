import React from 'react';
import DashboardCard from '../dashboard-card/dashboard-card';
import PageUsage from './page-usage';
export default function Usage({ item, itemProxy }) {
    return (React.createElement(DashboardCard, { label: "NEEDS A NAME", iconClass: "far fa-fw fa-chart-bar", subTitle: "NEEDS A DESCRIPTION", className: "Usage" },
        item.document?.type === 'form' && React.createElement(FormUsagePlaceholder, null),
        item.document?.type === 'page' && (React.createElement(PageUsage, { item: item, itemProxy: itemProxy }))));
}
function FormUsagePlaceholder() {
    return (React.createElement("div", { className: "usage-stats" },
        React.createElement("span", { className: "usage-title" },
            "Usage Statistics",
            React.createElement("i", { className: "fad fa-fw fa-chart-bar" })),
        React.createElement("div", { className: "usage-description" }, "This will show where the document is used. i.e. 1 Action button, 2 Public forms")));
}
