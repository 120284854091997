import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { useQueryStringProxy, useTabContent, useTabContext, } from '@rapid/convergence';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useDocument, { useStateLink, } from '../../../../store/designer-store';
import EditableTitle from '../../../editable-title/editable-title';
import LinkDetails from './link-details';
export default function State({ state, stateKey, isDefaultState, states, onDashboard, }) {
    const { addTab } = useTabContext();
    const qs = useQueryStringProxy();
    const [, setQuery] = useSearchParams();
    const { match } = useTabContent();
    const id = isNaN(+match.params.id) ? undefined : +match.params.id;
    const [, documentProxy] = useDocument(id);
    const { link, deleteLink, generateLink, openLink: onOpenLink, copyLink: onCopyLink, url, } = useStateLink(stateKey);
    const [newName, setNewName] = useState('');
    const [editingName, setEditingName] = useState(false);
    const [linkVisible, setLinkVisible] = useState(onDashboard ? true : false);
    const onDeleteLinkClick = () => {
        deleteLink().then(() => {
            if (!onDashboard) {
                setLinkVisible(false);
            }
        });
    };
    const onGenerateLink = () => {
        generateLink().then(() => {
            documentProxy.save();
        });
    };
    const onToggleLinkVisible = () => {
        if (onDashboard) {
            return;
        }
        setLinkVisible(!linkVisible);
        qs.state = stateKey;
        setQuery(qs.toString());
    };
    const onEditState = (e) => {
        e.stopPropagation();
        e.preventDefault();
        addTab(`${match.params.type}/${match.params.id}/state/${stateKey}`, true);
    };
    const onDeleteState = (e) => {
        e.stopPropagation();
        e.preventDefault();
        delete documentProxy.document.head.states[stateKey];
        delete qs.state;
        setQuery(qs.toString());
    };
    const onChangeStateName = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditingName(true);
    };
    const onNameChange = (e) => {
        setNewName(e.target.value);
    };
    const confirmNameChange = () => {
        const isDuplicate = states.some(i => i === newName);
        if (isDuplicate) {
            toast.warn(`All state keys must be unique. ${newName} has already been used.`);
            return;
        }
        const isEmpty = !newName;
        if (isEmpty) {
            return;
        }
        if (qs.state === stateKey) {
            qs.state = newName;
            setQuery(qs.toString());
        }
        documentProxy.document.head.states[stateKey].label = newName;
        setEditingName(false);
    };
    const onConfirmNameChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        confirmNameChange();
    };
    const cancelNameChange = () => {
        setNewName('');
        setEditingName(false);
    };
    const onCancelNameChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        cancelNameChange();
    };
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            confirmNameChange();
        }
        if (e.key === 'Escape') {
            cancelNameChange();
        }
    };
    const stateClass = useComposedClassName(function* () {
        yield 'state';
        if (qs.state === stateKey) {
            yield 'active';
        }
        if (editingName) {
            yield 'editing';
        }
    }, [qs.state, editingName]);
    return (React.createElement("div", { className: stateClass },
        React.createElement(EditableTitle, { title: state.label, editingTitle: newName, onTitleChange: onNameChange, onKeyDown: onKeyDown, placeholder: state.label, iconClass: "state-icon fal fa-fw fa-rectangle-history", onTitleClick: onToggleLinkVisible, editing: editingName, tags: React.createElement("div", { className: "link-details" },
                !link && (React.createElement("button", { className: "add-link", title: "Share Document", onClick: onGenerateLink },
                    React.createElement("i", { className: "add-link-icon fak fa-fw fa-link-plus" }))),
                !!link && (React.createElement("button", { className: "has-link", title: "This state has an active share" },
                    React.createElement("i", { className: "has-link-icon fal fa-fw fa-link" })))), actions: !isDefaultState ? (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "change-state-name", title: "Change state name", onClick: onChangeStateName },
                    React.createElement("i", { className: "change-state-name-icon fal fa-fw fa-pencil" })),
                React.createElement("button", { className: "edit-state", title: "Edit state", onClick: onEditState },
                    React.createElement("i", { className: "edit-state-icon fal fa-fw fa-file-pen" })),
                React.createElement("button", { className: "delete-state", title: "Delete state and share", onClick: onDeleteState },
                    React.createElement("i", { className: "delete-state-icon fal fa-fw fa-trash-can" })))) : (React.createElement("span", { className: "default-state-message" }, "Document without any state")), editingActions: React.createElement(React.Fragment, null,
                React.createElement("button", { className: "confirm-name-change", title: "Confirm name change", onClick: onConfirmNameChange },
                    React.createElement("i", { className: "confirm-name-change-icon fal fa-fw fa-check" })),
                React.createElement("button", { className: "cancel-name-change", title: "Cancel name change", onClick: onCancelNameChange },
                    React.createElement("i", { className: "cancel-name-change-icon fal fa-fw fa-times" }))) }),
        linkVisible && (React.createElement("div", { className: "link-drawer" },
            !!isDefaultState && (React.createElement("div", { className: "default-state-message" },
                "The Default state will always be the empty document without any state. ",
                React.createElement("br", null),
                "This can be used to share without state.")),
            !!link && (React.createElement("div", { className: "link" },
                React.createElement(LinkDetails, { url: url, link: link, stateKey: stateKey, onOpenLink: onOpenLink, onCopyLink: onCopyLink, onDeleteLink: onDeleteLinkClick, state: state }))),
            !link && (React.createElement("div", { className: "no-link" },
                React.createElement("button", { className: "generate-link", onClick: onGenerateLink },
                    React.createElement("i", { className: "generate-link-icon fal fa-fw fa-share-nodes" }),
                    "Share ",
                    state?.label)))))));
}
