import React, { useMemo } from 'react';
import { controlRegistry } from '@rapid/adaptive-framework';
import useComposedClassName from '@rapid-platform/use-composed-class-name';
export default function ElementTitleRender({ node, onHoverNode, onSelectNode, }) {
    const control = useMemo(function getControlById() {
        const control = controlRegistry.getById(node.element.$type);
        return control;
    }, [node.element.$type]);
    const onHover = (hover) => () => {
        if (hover) {
            onHoverNode(node);
        }
        else {
            onHoverNode();
        }
    };
    const _onSelectNode = () => {
        onSelectNode(node);
    };
    const iconClass = useComposedClassName(function* () {
        yield 'fal fa-fw';
        if (!!control.icon && typeof control.icon === 'string') {
            yield control.icon;
        }
    }, [control]);
    return (React.createElement("div", { onMouseOver: onHover(true), onMouseOut: onHover(false), onClick: _onSelectNode },
        React.createElement("i", { className: iconClass }),
        node.element.title ?? node.element.attributes?.label ?? 'Untitled'));
}
