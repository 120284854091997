import React from 'react';
import { useApplicationStore, useListItem } from '@rapid/sdk';
import { useCloseDocumentTabs } from '../../hooks/use-close-tab';
import { toast } from 'react-toastify';
import { removeIdFromWorkspace } from '../../store/designer-store';
export default function DeleteDialog({ onCancel, onSave }) {
    const [appData, appDataProxy] = useApplicationStore();
    const [item, itemProxy] = useListItem('Adaptive Documents', appData.activeDocument);
    const closeTab = useCloseDocumentTabs(item);
    function onDelete(ev) {
        itemProxy.delete();
        closeTab(ev);
        removeIdFromWorkspace(+item.id);
        toast.success(`${item.title || `Document ${item.id}`} deleted`);
        onSave();
    }
    return (React.createElement("div", { className: "delete-dialog" },
        React.createElement("h2", { className: "dialog-title" },
            React.createElement("i", { className: "fal fa-fw fa-trash-can" }),
            "Delete ",
            item.title ? item.title : `${item.__metadata.type} - ${item.id}`),
        React.createElement("div", { className: "description" }, "Are you sure you want to delete this document?"),
        React.createElement("div", { className: "actions" },
            React.createElement("button", { className: "cancel", onClick: onCancel }, "Cancel"),
            React.createElement("button", { className: "delete close", onClick: onDelete }, "Delete"))));
}
