import { store } from '@rapid/sdk';
import { getListByEntityType } from '@rapid/sdk/lib/store/list';
import React from 'react';
import { toast } from 'react-toastify';
export default function PagePlacement({ page, categories, itemProxy, }) {
    const category = categories.find(c => c.id === page.category);
    const list = getListByEntityType(page.entity_type_id);
    const onDeletePagePlacement = () => {
        toast
            .promise(store.api.apps[page.id].delete(), {
            pending: 'Deleting app document',
            success: 'App document deleted',
            error: 'There was an error',
        })
            .then(() => {
            itemProxy.getDataArray('pages', store.api.apps.documents[itemProxy.id]);
        });
    };
    return (React.createElement("div", { className: "page-placement" },
        React.createElement("div", { className: "category-header" },
            React.createElement("h5", { className: "category-title" }, category.title),
            React.createElement("button", { className: "trash", onClick: onDeletePagePlacement },
                React.createElement("i", { className: "fal fa-fw fa-trash-can" }))),
        React.createElement("p", { className: "category-config" },
            "list: ",
            list.ListName)));
}
