import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import '@rapid/adaptive-designer/css/designer.css';
import '@rapid/adaptive-framework/css/framework.css';
import './index.scss';
import { getLocalStorageTheme } from './store/store';
import { initSDKStore } from './store/init-sdk-store';
import { rapidConnection } from '@rapid/adaptive-connections';
import { connectionRegistry } from '@rapid/adaptive-framework';
import { taskContextConnection } from '@rapid/adaptive-connections/lib/connections';
import { getSiteTheme } from './store/designer-store';
import { onLogin } from '@rapid/sdk';
connectionRegistry.add(rapidConnection.name, rapidConnection);
connectionRegistry.add(taskContextConnection.name, taskContextConnection);
initSDKStore();
getLocalStorageTheme();
onLogin(async (innerStore) => {
    getSiteTheme();
});
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
