import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { toast } from 'react-toastify';
import { useTheme } from '../../../../store/store';
const ENCODED_LOGO = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAABHCAYAAABWB1Q3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP1SURBVHgB3ZqNUdtAEIXfMRTgEkQHpAPTAakgpALsCnKpQKaCOBUkHcQdxB2gdOAOLrc6gf/0c7u6PWR/MzDGCDNP77T7biXgI1i5H8jMDXLz4p799yeUbo6M5BVaugIOi/q1wTdkJLej1n8Vzeu5d/cLMmGQC3LT4PXk3Z13+A5Ls4My+Rw1+NPy7sx/LZCBPEJL94T9kj3G4Nn/fgZl9IWGJdtXeGb+9yWU0Rd6Ayo4xcBR6u1GV2hoJzbqWOV2o+2oZRw713RVTygVIANenzRQi4Z6QmVLsfAnSKXd6AgNebaABDpBCu0mvdDDPCtDJURoOGohdfONECIKJCSt0BAOUgT15CEirVCDX0jHY8p2k05oyLP3SEnCEJFOqE6yoRDxiASkEfriSGQBDehaTdBuxgvl5FkZBRK0mxSOWvDhTRQS7FnHCaWqyG8nW78CPoPHbGwNGCdUEsJJ5NJs/KsNeCzGhAi50L7xSDdrL7KqXzl8B5cRuxuZ0OHxSBvVkThy1eEneIj3rFJHLbhuOry8u3n8OdzCJLpW+UJlebbyIldn75JwOgE8RINvvlBJ2Da9LYhOAM9V6tvMdsMTGgoQN5Kt8Wy6r0Wa0vMLUwFmiODdkli5V/CvzbuWa7Pts/+Ctylg3c6Id1SSZ8mpGJHh2CV4sEJEnNCQZ5/AowIt21jkISJqFcQ6asF1kwpQrJtv8F2NLo7D12j77b4hKizMHSSUblWHeA4OD82K6GTY0fbbfUP/mBvaD7Hgh4hBV/uFyvPsFlJCu+GGiPuhwXf30qWGbEAlvwCH2HbSh+x/97abbkdvwJ+2c9pJH7IQ0Tv4bnc0dwHqYuWoPswZf0En6FPbye5y1IKLUZgbSVztCBHnjtJ4kT+I3ng3H6AB39XWdnPuqGR34vAVWkg+u8XVY6FjxyMahD0rdwmfTSL2QlOMR/Tg71lP5kuHjlqkGY+kRxYiju6eh2I0lXYyBH8//B4igqPpxyM68AvTe4gwzdMj3Hnp2rupV2n7EIaIW4SlsEZQHzNwmvk//NdU6FiqoW1UNFT8DEtoffec/xhr6dascWcYUi9yPKpa03HbgieUu8xDyLeYALeso+P77K4uHEvzGxMhXihNAV1Uaa+arFlhQqSeAm66tkkfTayjFkONOqSkLI+NS0gxBdzVY8qlWWPCDDvaPwWsmjvY8mFYJsZMATdN0Zm8SEI2BZz49dgGfwoYrseLEklwpoBVEwI2uEC6ipE9+XnbFJ0KF8r50g1TwH1op1A+waTD5dzRw014uB5XuAKOhdJD/yHP7g6e8LoK9sUoFKAQDq5gqZ5yOgXcTjWUp4HcLJ3F1RP5wMMl8x+n6nj0x6QoCQAAAABJRU5ErkJggg==';
export default function LinkDetails({ state, link, url, stateKey, onOpenLink, onCopyLink, onDeleteLink, }) {
    const [, isDarkTheme] = useTheme();
    const ConfirmDelete = ({ closeToast }) => {
        const onConfirmDelete = () => {
            onDeleteLink(link);
            closeToast();
        };
        const onCancel = () => {
            closeToast();
        };
        return (React.createElement("div", { className: "confirm-delete-link" },
            React.createElement("h4", { className: "confirm-delete-link-title" }, "Are you absolutely sure?"),
            React.createElement("div", { className: "confirm-delete-link-subtitle" },
                "This action cannot be undone. This will permanently delete the",
                ' ',
                React.createElement("span", { className: "highlight" }, state?.label),
                " share. Any places this document has been shared will no longer be accessible."),
            React.createElement("div", { className: "actions" },
                React.createElement("button", { onClick: onConfirmDelete, className: "delete-link danger" },
                    React.createElement("i", { className: "fal fa-fw fa-trash-can" }),
                    " Delete"),
                React.createElement("button", { onClick: onCancel, className: "cancel-delete-link text" },
                    React.createElement("i", { className: "fal fa-fw fa-times" }),
                    " Cancel"))));
    };
    const onDeleteLinkInner = () => {
        toast.error(ConfirmDelete, {
            autoClose: false,
            closeOnClick: false,
        });
    };
    const downloadQrCode = () => {
        var canvas = document.getElementById('qr-code');
        var ctx = canvas.getContext('2d');
        ctx.font = '42px serif';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        var imageURI = canvas.toDataURL('image/jpg');
        var link = document.createElement('a');
        link.download = `${stateKey}-link.jpg`;
        link.href = imageURI;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    // NOTE: The QRCode library we are using doesn't take css variables for colors. So I've had to spoof the dark theme change
    const fgColor = isDarkTheme ? '#ECEDEE' : '#272D31';
    const bgColor = isDarkTheme ? '#151718' : '#FBFCFD';
    return (React.createElement("div", { className: "link-details" },
        React.createElement("div", { className: "full-link-container" },
            React.createElement("div", { className: "full-link" },
                React.createElement("span", { className: "link-container" }, url),
                React.createElement("button", { className: "open-link", title: "Open in new window", onClick: onOpenLink },
                    React.createElement("i", { className: "open-link-icon open fal fa-fw fa-arrow-up-right-from-square" })),
                React.createElement("button", { onClick: onCopyLink, className: "copy-link", title: "Copy to clipboard" },
                    React.createElement("i", { className: "copy-link-icon fal fa-fw fa-copy" })))),
        React.createElement("div", { className: "qr-code-container" },
            React.createElement(QRCode, { value: url, eyeRadius: 3, eyeColor: "#0090FF", fgColor: fgColor, bgColor: bgColor, logoImage: ENCODED_LOGO, logoWidth: 58, logoHeight: 70, ecLevel: "H", id: "qr-code" })),
        React.createElement("div", { className: "actions" },
            React.createElement("button", { title: "Dowload QR code", className: "download-qr-code", onClick: downloadQrCode },
                React.createElement("i", { className: "download-qr-code-icon fal fa-fw fa-download" }),
                React.createElement("span", null, "Download QR")),
            React.createElement("button", { title: "Delete share (state will remain)", className: "delete-link", onClick: onDeleteLinkInner },
                React.createElement("i", { className: "fal fa-fw fa-trash-can" }),
                React.createElement("span", null, "Delete link")))));
}
