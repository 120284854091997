import { Wizard as FormWizard } from '@rapid/adaptive-designer';
import { defaultFormBody, defaultFormDocument, defaultFormHead, } from '@rapid/adaptive-framework';
import { store } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import jsonpath from 'jsonpath';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import slugify from 'slugify';
import { proxy, snapshot, useSnapshot } from 'valtio';
import PagesWizard from './pages/pages-wizard';
const id = nanoid();
const prox = proxy({
    item: {
        classification: 'Draft',
        document: {
            type: 'form',
            head: defaultFormHead,
            body: defaultFormBody,
        },
    },
    rendererId: `create:${id}`,
});
function reset(type) {
    prox.item = {
        classification: 'Draft',
        document: {
            type,
            head: defaultFormHead,
            body: defaultFormBody,
        },
    };
    prox.item.document.head.title = '';
}
export async function createDocument() {
    const item = snapshot(prox.item);
    const res = store.api.Lists['Adaptive Documents'].items.postJson({}, item);
    toast.promise(res, {
        pending: 'Creating document',
        success: 'Document created',
        error: 'Unable to create document. Please try again. if the error persists, contact your support personal.',
    });
    const [newItem] = await res;
    reset(item.document.type);
    return newItem;
}
export async function cloneDocument() {
    const activeDocument = getListItem('Adaptive Documents', store.applicationData.activeDocument);
    const documentSnap = snapshot(activeDocument);
    prox.item.document.body = documentSnap.document.body;
    const item = snapshot(prox.item);
    const res = store.api.Lists['Adaptive Documents'].items.postJson({}, item);
    toast.promise(res, {
        pending: `Cloning ${documentSnap.title}`,
        success: 'Document cloned',
        error: 'Unable to clone document. Please try again. if the error persists, contact your support personal.',
    });
    const [newItem] = await res;
    reset(item.document.type);
    return newItem;
}
export default function CreationWizard({ type = 'form', }) {
    const item = useSnapshot(prox.item);
    useEffect(function clearItemOnTypeChange() {
        reset(type);
    }, [type]);
    const onDataChange = (element, data, path, key) => {
        if (!prox.item.document) {
            prox.item.document = defaultFormDocument;
            prox.item.document.type = type;
            prox.item.document.head = defaultFormHead;
        }
        if (!!path && key === 'data') {
            jsonpath.value(prox.item, path, data);
            if (element.id === 'document-title') {
                const slug = slugify(data, {
                    lower: true,
                    strict: true,
                    trim: true,
                    remove: /[*+~.()'"!:@]/g,
                });
                jsonpath.value(prox.item, '$.slug', slug);
            }
        }
    };
    if (type === 'form') {
        return (React.createElement(FormWizard, { item: item, onDataChange: onDataChange, rendererId: `${prox.rendererId}:forms` }));
    }
    else {
        return (React.createElement(PagesWizard, { item: item, onDataChange: onDataChange, rendererId: `${prox.rendererId}:pages` }));
    }
}
